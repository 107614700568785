import { useMemo, useState } from "react"
import styled from "styled-components"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { useBillingForm } from "src/components/Account/BillingPortal/BillingAddress/useBillingForm"
import { AddCardForm } from "src/components/Account/BillingPortal/PaymentMethod/AddCardForm"
import { TUserBillingInfo } from "src/data/billing/types/billingTypes"
import { TShippingDestination } from "src/data/ecommerce/ecommerceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function ReplacePaymentMethod({
  userBillingInfo,
  shippingDestinations,
}: {
  userBillingInfo: TUserBillingInfo
  shippingDestinations: TShippingDestination[]
}) {
  const { t, langKeys } = useTranslate()

  const [replaceCard, setReplaceCard] = useState(false)

  const {
    billingAddress,
    vatNumber,
    setFormFields,
    setFormVatNumber,
    shippingDestination,
  } = useBillingForm(
    userBillingInfo.billing_address,
    userBillingInfo.vat_number,
    shippingDestinations
  )

  const { stripe_api_gateway, chargebee_api_gateway } =
    shippingDestination?.payment_gateway ?? {}

  const stripeSdkPromise = useMemo(() => {
    if (!stripe_api_gateway) {
      return null
    }

    return loadStripe(stripe_api_gateway)
  }, [stripe_api_gateway])

  if (!chargebee_api_gateway || !stripe_api_gateway) {
    return (
      <MBanner type="error">{t(langKeys.failed_general_error_body)}</MBanner>
    )
  }

  return replaceCard ? (
    <CardFormBox>
      <Elements stripe={stripeSdkPromise} key={stripe_api_gateway}>
        <AddCardForm
          userBillingInfo={userBillingInfo}
          billingAddress={billingAddress}
          vatNumber={vatNumber}
          setFormFields={setFormFields}
          setFormVatNumber={setFormVatNumber}
          replace={true}
          chargebeeGateway={chargebee_api_gateway}
          shippingDestinations={shippingDestinations}
          stripeApiGateway={stripe_api_gateway}
        />
      </Elements>
    </CardFormBox>
  ) : (
    <ReplaceCardButton onClick={() => setReplaceCard(true)}>
      Replace Card
    </ReplaceCardButton>
  )
}

const CardFormBox = styled.div`
  margin-top: ${spacing.L};
`

const ReplaceCardButton = styled(TextButton)`
  margin-top: ${spacing.S};
  align-self: flex-end;
`
